import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { DownloadExcel } from "../../services/ExcelService";
import { connect } from "react-redux";
import axios from "axios";
import appConfig from "../../config/appConfig";
import alertify from "alertifyjs";

function UserLogs() {

  const [tableProps, setTableProps] = useState({
    data: [],
    isLoading: true,
    page: 0,
    totalCount: 0
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [querySearch, setQuerySearch] = useState('');

  const loadData = () => {
    setTableProps({
      ...tableProps,
      isLoading: true,
    });
    let url = 'logs/GetLogs?page=' + (currentPage + 1);
    axios.get(appConfig.baseApiUrl + url).then((resJson: any) => {
      try {
        if (resJson.data.success&&resJson.data.totalItemCount>0) {
          setTableProps({
            data: resJson.data.data.filter(function (obj) {
              return Object.keys(obj).some(function (key) {
                return obj[key] ? (obj[key]).toString().toLowerCase().includes(querySearch.toLowerCase()) : false
              })
            }),
            isLoading: false,
            page: currentPage,
            totalCount: resJson.data.totalItemCount
          });
          return;
        }
        else{
          alertify.warning("Gösterilecek veri bulunamadı!");
          setTableProps({
            data: [],
            isLoading: false,
            page: currentPage,
            totalCount: 0
          });
        }
      } catch (error) {
        throw new Error("bir hata oluştu");
      }
    }).catch((error) => {
      alertify.error(error.message);
    }).finally(()=>{
      setTableProps((prevStatus) => ({
        ...prevStatus,
        isLoading: false
      }));
    });
  };

  useEffect(() => {
    var delay = setTimeout(() => {
      loadData();
    }, 500);
    return () => clearTimeout(delay);
  }, [querySearch, currentPage]);



  return (
    <MaterialTable
      title="Kullanıcı Kayıtları"
      onSearchChange={setQuerySearch}
      columns={[
        { title: "Mesajlar", field: "message" },
        { title: "Tarih", field: "reportDate", type: 'datetime' },
        { title: "Kim", field: "createdBy", },
      ]}
      // data={query =>
      //   new Promise((resolve, reject) => {
      //     let url = 'logs/GetLogs?page=' + (query.page + 1);
      //     axios.get(appConfig.baseApiUrl + url).then((resJson: any) => {
      //       if (resJson.data.success) {
      //         resolve({
      //           data: resJson.data.data,
      //           page: query.page,
      //           totalCount: resJson.data.totalItemCount,
      //         });
      //         return;
      //       }
      //       throw new Error("bir hata oluştu");
      //     }).catch((error) => {
      //       alertify.error(error.message);
      //     });
      //   })
      // }
      onChangePage={setCurrentPage}
      localization={{
        pagination: {
          labelDisplayedRows: "{from}-{to} of {count}",
        },
        body: {
          emptyDataSourceMessage: "No records to display",
          filterRow: {
            filterTooltip: "Filter",
          },
        },
        toolbar: {
          searchTooltip: "Ara",
          searchPlaceholder: "Aranacak kelimeyi giriniz",
        },
      }}
      options={{
        sorting: true,
        search: true,
        searchFieldAlignment: "right",
        paging: true,
        pageSizeOptions: [10],
        pageSize: 10,
        paginationType: "normal",
        showFirstLastPageButtons: true,
        showTextRowsSelected: false,
        // paginationPosition: "both",
        exportAllData: true,
        actionsColumnIndex: -1,
        selection: false,
        columnsButton: false,
        toolbar: true,
      }}
      actions={[
        {
          icon: 'refresh',
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => loadData()
        }
      ]}
      {...tableProps}
    />
  );
}


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLogs);
import * as actionTypes from "../actions/actionTypes";
import initialStates from './initialStates';

const loginReducer = (state = initialStates.userContext, action) => {
  let newState = state;
  switch (action.type) {
    case actionTypes.PRE_LOGIN:
      //TO DO: ilgilenilecek burası saçma
      localStorage.setItem("cookie", action.payload);
      return (newState = action.payload);
    case actionTypes.LOGIN:
      localStorage.setItem("userContext", JSON.stringify(action.payload));
      return (newState = action.payload);
    case actionTypes.LOGOUT:
      localStorage.removeItem("userContext");
      return (newState = {});
    case actionTypes.SET_LOGIN_CONTEXT:
      localStorage.setItem("userContext", JSON.stringify(action.payload));
      return (newState = action.payload);
    case actionTypes.SET_IS_CHANGED_PASSWORD:
      var userContext = JSON.parse(localStorage.getItem("userContext"));
      userContext.IsChangedPassword = action.payload;
      localStorage.setItem("userContext", JSON.stringify(userContext));
      return (newState = userContext);
    default:
      return newState;
  }
};

export default loginReducer;

/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { Component } from "react";
import Login from "./pages/login/Login";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/main/Home";
import NotFound from "./pages/NotFound";
import PrivateRouter from "./components/PrivateRouter";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as loadingActions from "./store/actions/loadingActions";
import * as loginActions from "./store/actions/loginActions";
import UpdatePassword from "./pages/updatePassword/UpdatePassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ForgettenPassword from "./pages/forgettenPassword/ForgettenPassword";
import AuthenticateTwoFactor from "./pages/authenticate2fa/AuthenticateTwoFactor";


var activeRequestCount = 0;


class App extends Component {
  constructor(props) {
    super(props);
    
    axios.interceptors.request.use(
      function (config) {
        activeRequestCount++;
        if (activeRequestCount === 1) props.actions.setIsLoading(true);

        var accessToken = localStorage.getItem("accessToken");
        config.headers["Content-Type"] = "application/json";
      
        if (accessToken !== null && accessToken !== "undefined")
          config.headers.Authorization = "Bearer " + accessToken;

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        activeRequestCount--;
        if (activeRequestCount === 0) {
          props.actions.setIsLoading(false);
        }
        return response;
      },
      (error) => { 
        activeRequestCount--;
        if (activeRequestCount === 0) {
          props.actions.setIsLoading(false);
        }
        return Promise.reject(error);
      }
    );

    this.state = {
      data: {},
      users: [],
    };
  }

  isLoggedIn(): boolean {
    return false;
  }
  render() {
    return (
      <div>
        <div className={"main-container"}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/updatePassword" component={UpdatePassword} />
            <Route path="/resetPassword" component={ResetPassword} />
            <Route path="/forgettenPassword" component={ForgettenPassword} />
            <Route path="/authenticate-2fa" component={AuthenticateTwoFactor}></Route>
            <PrivateRouter path="/" component={Home}></PrivateRouter>
            <PrivateRouter exact path="/" component={Home}></PrivateRouter>
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    usercontext: state.loginReducer,
    sideNavIsOpen: state.sideNavReducer,
    isLoading: state.loadingReducer,
    totalRequest: state.totalRequestReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(loginActions.login2fa, dispatch),
      logout: bindActionCreators(loginActions.logout, dispatch),
      setLoginContext: bindActionCreators(
        loginActions.setLoginContext,
        dispatch
      ),
      setIsLoading: bindActionCreators(loadingActions.setIsLoading, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

export const PRE_LOGIN = "PRE_LOGIN"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const SET_LOGIN_CONTEXT = "SET_LOGIN_CONTEXT"
export const SET_PASSWORD_INCONTEXT = "SET_PASSWORD_INCONTEXT"
export const SIDE_NAV_OPEN = "SIDE_NAV_OPEN"
export const SIDE_NAV_CLOSE = "SIDE_NAV_CLOSE"

export const SET_ISLOADING = "SET_ISLOADING"

export const SET_CURRENCIES = "SET_CURRENCIES";

export const SET_IS_CHANGED_PASSWORD = "SET_IS_CHANGED_PASSWORD"
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from "react-redux";
import * as loginActions from "../store/actions/loginActions";
import { bindActionCreators } from "redux";
import container from "../ioc/IocContainer";
import IAuthService from "../interfaces/ServiceInterfaces/IAuthService";
import * as changePasswordActions from "../store/actions/changePasswordActions";
import TYPES from "../ioc/types";
import * as consts from "../common/consts";

const authService = container.get<IAuthService>(TYPES.IAuthService);

const IsLoggedIn = () => {
  return authService.isLoggedIn();
}

const IsChangedPassword = () => {
  return authService.isChangedPassword();
}


const PrivateRoute = ({ actions, component: Component, ...rest }) => {
  const isLoggedIn = IsLoggedIn();
  const isChangedPassword = IsChangedPassword();

  useEffect(() => {
    var context = localStorage.getItem(consts.USER_CONTEXT);
    if (null != context) {
      var user = JSON.parse(context);
      actions.setIsChangedPassword(user.IsChangedPassword);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          isChangedPassword ?
            (<Component {...props} />)
            :
            (<Redirect to={{ pathname: '/updatePassword', state: { from: props.location } }} />)
        )
          :
          (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)

      }
    />
  )
}

function mapStateToProps(state) {
  return {
    usercontext: state.loginReducer,
    isChangedPassword: state.changePasswordReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(loginActions.login2fa, dispatch),
      setLoginContext: bindActionCreators(loginActions.setLoginContext, dispatch),
      logout: bindActionCreators(loginActions.logout, dispatch),
      setIsChangedPassword: bindActionCreators(changePasswordActions.setIsChangedPassword, dispatch),

    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { TextField, FormGroup, Button } from "@material-ui/core";
import { connect } from "react-redux";
import axios from "axios";
import alertify from "alertifyjs";
import appConfig from "../../config/appConfig";
import { Container, Row, Form} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import logo from "../../assets/istanbul.png";
import { bindActionCreators } from "redux";
import * as loginActions from "../../store/actions/loginActions";
import { Redirect, useHistory } from "react-router-dom";


function ResetPassword(props: any) {

  const queryParameters = new URLSearchParams(props.location.search)
  const [token, setToken] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [user, setUser] = useState({ id: 0, nameSurname: "", username: "", email:"", phone:"" });
  const history = useHistory();

  useEffect(() => {
    if ( queryParameters.has("userToken") )
    {
      let userToken = queryParameters.get("userToken")
      if ( userToken != null )
      {
        setToken(userToken);
        getUserByToken(userToken);
      }
      else
      {
        setIsTokenValid(false)
      }
    }
    else
    {
      setIsTokenValid(false)
    }
  }, []);

  const changePassword = (user) => {
    let myHeaders = new Headers({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });

    fetch(appConfig.baseApiUrl + "users/UpdatePassword", {
      headers: myHeaders,
      method: 'POST',
      body: JSON.stringify(user)
    })
    .then(async response => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response statusText
          const error = data.responseMessage;
          return Promise.reject(error);
      }
      else
      {
        alertify.success("İşlem Başarılı!");
        history.push('/login');
      }
    })
    .catch(error => {
      alertify.error(error)
    })

  }

  const getUserByToken = (userToken) => {
    let myHeaders = new Headers({
      'Authorization': 'Bearer ' + userToken,
      'Content-Type': 'application/json'
    });

    fetch(appConfig.baseApiUrl + "users/GetUserChangedPassword", {
      headers: myHeaders,
      method: 'GET'
    })
    .then(async response => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }
      var resData=data.responseData?.subData;
      setUser(resData.user);
      setToken(resData.accessToken);
    })
    .catch(error => {
        setIsTokenValid(false)
    });
  };

  if (isTokenValid === false)
  {
    return <Redirect to="/login" />;
  }
  return (
    
    <div className="login-main">
      <Container className="login-main-container">
        <Row className="justify-content-md-center">
          <Formik
            initialValues={{
              id: user.id,
              nameSurname: user.nameSurname,
              userName: user.username,
              email: user.email,
              phone:  user.phone,
              password: "",
              passwordConfirm: "",
            }}
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize
            validate={(values) => {


            const errors = {} as any;

            if (!values.password)
              errors.password = "Lütfen sifre giriniz!"
            if (values.password !== values.passwordConfirm) {
              errors.passwordConfirm = "Girilen şifreler aynı olmalıdır";
            }

            return errors;
          }}
          onSubmit={(values, actions) => {
            changePassword(values);
          }}
        >
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit} style={{ maxWidth: 250 }}>
                    
          <Form>
              <Image src={logo} fluid style={{width:"300px"}} />
              <FormGroup>
                <TextField
                  name="password"
                  type="password"
                  label="Yeni Şifre"
                  value={formProps.values.password}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: '#B0B1B3' } }}
                  {...(formProps.errors.password && {error:true,helperText:formProps.errors.password})}
                />
                <TextField
                  name="passwordConfirm"
                  type="password"
                  label="Yeni Şifre Onayla"
                  value={formProps.values.passwordConfirm}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: '#B0B1B3' } }}
                />
                {formProps.errors.passwordConfirm &&
                  formProps.touched.passwordConfirm ? (
                    <div style={{ color: "red" }}>
                      {formProps.errors.passwordConfirm}
                    </div>
                  ) : null}
                <Button color="primary" type="submit">
                  Güncelle
                </Button>
              </FormGroup>
              </Form>
    
            </form>
          )}
        </Formik>
        </Row>
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userContext: state.loginReducer,
    isLoading: state.loadingReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      logout: bindActionCreators(loginActions.logout, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

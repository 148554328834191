import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Grid, TextField, FormGroup, Button } from "@material-ui/core";
import { connect } from "react-redux";
import axios from "axios";
import alertify from "alertifyjs";
import appConfig from "../../config/appConfig";

const phoneRegex = RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

Profile.prototype.IsEmpty = (input) => {
  switch (typeof input) {
    case 'undefined': return true
    case 'string':
    case 'object':
      return Object.keys(input).length == 0
    case 'boolean':
    case 'bigint':
    case 'number': return input == 0
  }
};

function Profile(props: any) {
  const [user, setUser] = useState({ id: 0, nameSurname: "", username: "", email: "", phone: "" });
  

useEffect(() => {
  if (!Profile.prototype.IsEmpty(props.userContext.Id)) {
    getUser();
  }
}, [props.userContext.Id]);

const getUser = () => {
  axios.get(appConfig.baseApiUrl + "users/GetUser").then((resJson) => {
    var user = resJson.data;
    if (user) {
      setUser(prev => ({
        ...prev,
        nameSurname: user.nameSurname,
        email: user.email,
        username: user.userName,
        phone: user.phoneNumber
      }));
    }
  });
};

return (
  <div>
    <Grid container>
      <Grid item md={3} xs={12}>
        <Formik
          initialValues={{
            id: user.id,
            nameSurname: user.nameSurname,
            userName: user.username,
            email: user.email,
            phone: user.phone,
            password: "",
            passwordConfirm: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
          validate={(values) => {
            const errors = {} as any;

            if (!values.userName)
              errors.userName = "Lütfen kullanici ismini giriniz!"
            if (!values.nameSurname)
              errors.nameSurname = "Lütfen kullanicinin ismini ve soyismini giriniz!"
            if (!values.email)
              errors.email = emailRegex.test(values.email) ? "" : "Lütfen geçerli e-mail giriniz!"
            if (!values.phone)
              errors.phone = phoneRegex.test(values.phone) ? "" : "En az 10 rakam gereklidir!"
            if (values.password !== values.passwordConfirm) {
              errors.passwordConfirm = "Girilen şifreler aynı olmalıdır";
            }

            return errors;
          }}
          onSubmit={(values, actions) => {
            axios
              .post(appConfig.baseApiUrl + "users/SaveMyProfile", values)
              .then((resJson) => {

                if (resJson.data.responseCode === 200) {
                  alertify.success("İşlem Başarılı!");
                  getUser();
                }
                else {
                  alertify.error(resJson.data.responseMessage)
                }
                getUser();
              }).catch((error) => {

                var errors = error.response.data.exception?.errors;
                for (let ind in errors) {
                  alertify.error(errors[ind]);
                }
                var responsError = error.response.data.ErrorMessage;
                if (responsError !== "")
                  alertify.error(responsError);
              });
          }}
        >
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit} style={{ maxWidth: 250 }}>
              <FormGroup>
                <TextField
                  name="nameSurname"
                  label="Ad Soyad"
                  value={formProps.values.nameSurname}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  {...(formProps.errors.nameSurname && { error: true, helperText: formProps.errors.nameSurname })}

                />
                <TextField
                  name="userName"
                  label="Kullanıcı Adı"
                  value={formProps.values.userName}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  autoComplete='off'
                  {...(formProps.errors.userName && { error: true, helperText: formProps.errors.userName })}
                />

                <TextField
                  name="email"
                  label="Email"
                  value={formProps.values.email}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  {...(formProps.errors.email && { error: true, helperText: formProps.errors.email })}
                />

                <TextField
                  name="phone"
                  label="Telefon Numarası"
                  value={formProps.values.phone}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  {...(formProps.errors.phone && { error: true, helperText: formProps.errors.phone })}
                />

                <TextField
                  name="password"
                  type="password"
                  label="Yeni Şifre"
                  value={formProps.values.password}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{autoComplete:'off'}}
                />
                <TextField
                  name="passwordConfirm"
                  type="password"
                  label="Yeni Şifre Onayla"
                  value={formProps.values.passwordConfirm}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                />
                {formProps.errors.passwordConfirm &&
                  formProps.touched.passwordConfirm ? (
                  <div style={{ color: "red" }}>
                    {formProps.errors.passwordConfirm}
                  </div>
                ) : null}
                <Button color="primary" type="submit">
                  Kaydet
                </Button>
              </FormGroup>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  </div>
);
}

function mapStateToProps(state) {
  return {
    userContext: state.loginReducer,
    isLoading: state.loadingReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React, { Component } from "react";
import { Container, Row, Form, Button, Spinner } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import logo from "../../assets/istanbul.png";
import { connect } from "react-redux";
import alertify from "alertifyjs";
import { Link } from "react-router-dom";
import axios from "axios";
import appConfig from "../../config/appConfig";

class ForgettenPassword extends Component<any, any> {
  state = {
    usernameOrEmail: "",
    isLoading: false
  };

  handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  async forgetPassword() {
    this.setState({ isLoading: true });
    await axios.post(appConfig.baseApiUrl + "users/ForgettenPassword", this.state)
      .then((resJson) => {
        if (resJson.data.statusCode === 200) {
          alertify.success("Mail gönderildi!");
        }
        else {
          alertify.error(resJson.data.responseData.messages)
        }
      })
      .catch((error) => {
        var errors = error.response.data?.exception?.errors;
        if (errors) {
          for (const item of errors) {
            alertify.error(item);
          }
        } else {
          var errorResponse = error.response.data?.ErrorMessage;
          errorResponse && alertify.error(errorResponse)
        };
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  getCancelButton() {
    return (
      <div className="d-flex justify-content-center">
        &nbsp; <Link to="/">Hesabın zaten var mı?</Link>
      </div>
    );
  }

  getForgettenPasswordButton() {
    if (!this.state.isLoading)
      return (
        <Button variant="primary" type="button" onClick={() => this.forgetPassword()}>
          Gönder
        </Button>
      );
    else
      return (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Gönder
        </Button>
      );
  }

  render() {
    return (
      <div className="login-main">
        <Container className="login-main-container">
          <Row className="justify-content-md-center">
            <Form>
              <Image src={logo} fluid style={{ width: "300px" }} />
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Kullanıcı Adı</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Kullanıcı adı giriniz."
                  onChange={this.handleChange}
                  name="usernameOrEmail"
                />
              </Form.Group>
              {this.getCancelButton()}
              {this.getForgettenPasswordButton()}
            </Form>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgettenPassword);

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ICustomer } from "../../interfaces/ICustomer";
import MaterialTable from "material-table";
import { ICustomerGroup } from "../../interfaces/ICustomerGroup";
import appConfig from "../../config/appConfig";
import { connect } from "react-redux";
import { DownloadExcel } from '../../services/ExcelService';
import alertify from "alertifyjs";

function CustomerListShort(props) {
  let history = useHistory();
  const [customers, SetCustomers] = useState([] as ICustomer[]);
  const [customerGroups, setCustomerGroups] = useState({});
  const [groups, setGroups] = useState({});


  const [tableProps, setTableProps] = useState({
    data: [] as ICustomer[],
    isLoading: true,
    page: 0,
    totalCount: 0
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [querySearch, setQuerySearch] = useState('');


  useEffect(() => {
    if (Object.entries(groups).length === 0 && groups.constructor === Object)
      axios
        .get(appConfig.baseApiUrl + "customer/GetCustomerGroups")
        .then((resJson) => {
          setCustomerGroups(resJson.data);
          var obj = {};
          resJson.data.map((item: ICustomerGroup) => {
            return (obj[item.id] = item.groupName);
          });
          setGroups(obj);
        });
  }, []);

  const loadData = () => {
    setTableProps({
      ...tableProps,
      isLoading: true,
    })

    let url = 'customer/GetAllCustomers?page=' + (currentPage + 1);
    axios.get(appConfig.baseApiUrl + url).then((resJson: any) => {
      try {
        var responseCustomers = resJson.data.responseData.subData;
      } catch (error) {
        throw new Error("bir hata oluştu");
      }
      if (responseCustomers.data.length > 0) {
        setTableProps({
          data: responseCustomers.data.filter(function (obj) {
            return Object.keys(obj).some(function (key) {
              return obj[key] ? (obj[key]).toString().toLowerCase().includes(querySearch.toLowerCase()) : false
            })
          }),
          isLoading: false,
          page: currentPage,
          totalCount: resJson.data.totalItemCount
        });
        return;
      }
      else {
        alertify.warning("Gösterilecek veri bulunamadı.");
        return;
      }

    }).catch((error) => {
      alertify.error(error.message);
    }).finally(() => {
      setTableProps((prevStatus) => ({
        ...prevStatus,
        isLoading: false
      }));
    });
  };
  useEffect(() => {
    var delay = setTimeout(() => {
      loadData();
    }, 500);
    return () => clearTimeout(delay);
  }, [querySearch, currentPage]);


  const openCustomerForm = (customer) => {
    // history.push("/customer", { customer,customerGroups });
    history.push({ pathname: "/customerForExchangeWithRate", state: { customer, customerGroups } })
  };

  return (
    <div>
      <MaterialTable
        title="Müşteri Listesi"
        onSearchChange={setQuerySearch}
        columns={[
          {
            title: "Ad Soyadı",
            field: "customerName",
            render: rowData => <span onClick={() => openCustomerForm(rowData)}>{rowData.customerName}</span>
          },
          {
            title: "Müşteri Grubu",
            field: "customerGroupId",
            lookup: groups,
          },
        ]}
        onChangePage={setCurrentPage}
        // data={customers}
        actions={[
          {
            icon: "edit",
            tooltip: "Müşteri Güncelle",
            onClick: (event, rowData) => {
              if ((rowData as ICustomer[]).length > 0)
                openCustomerForm(rowData[0]);
              else openCustomerForm(rowData);
            },
          },
          {
            icon: "add",
            tooltip: "Müşteri Ekle",
            isFreeAction: true,
            onClick: (event) => openCustomerForm(""),
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => loadData()
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          exportCsv: (columns, data) => {
            DownloadExcel("Müşteri Listesi", columns, data);
          },
          pageSize: 50,
          pageSizeOptions: [50, 100, 200]
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} of {count}",
          },
          header: {
            actions: "İşlemler",
          },
          body: {
            emptyDataSourceMessage: "No records to display",
            filterRow: {
              filterTooltip: "Filter",
            },
          },
          toolbar: {
            // showColumnsTitle?: string;
            // showColumnsAriaLabel?: string;
            exportTitle: "Dosyaya Aktar",
            // exportAriaLabel?: string;
            exportAriaLabel: "Excel'e Aktar",
            searchTooltip: "Ara",
            searchPlaceholder: "Aranacak kelimeyi giriniz",
          },
        }}
        {...tableProps}
      />
    </div>
  );
}
function mapStateToProps(state) {
  return {
    isLoading: state.loadingReducer,
    totalRequest: state.totalRequestReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListShort);
